/** @jsx jsx */
import { Fragment, useEffect, useState } from 'react'
import { Button, Container, Heading, Link as StyledLink, jsx } from 'theme-ui'
import {
  useAuth,
  useTranslate,
  useUser
} from '@chordcommerce/gatsby-theme-performance'
import Link from 'gatsby-link'
import Metadata from '~/components/Metadata'
import AccountAddressesList from '~/components/Account/Addresses/List'
import AccountAddressesForm from '~/components/Account/Addresses/Form'
import {
  Wrapper,
  PageHeader,
  PageHeaderLinks
} from '~/components/Account/Profile/Page/styles'

const AccountAddressPage = () => {
  const translate = useTranslate()
  const { getToken, logout } = useAuth()
  const { user, loadUser, createUserAddress } = useUser()
  const [isAdding, setIsAdding] = useState(false)

  useEffect(() => {
    if (!user.id) {
      getToken().then(() => loadUser())
    }
  }, [user, getToken, loadUser])

  const onLogout = async () => {
    await logout()
  }

  const onAdd = () => {
    setIsAdding(!isAdding)
  }

  const onAddSubmit = async ({ address, isDefaultAddress }) => {
    await createUserAddress(address, isDefaultAddress)
    setIsAdding(false)
  }

  return (
    <Container variant="small">
      <Metadata title={translate('account.profile.page_title')} />
      <Wrapper style={{ paddingBottom: 24 }}>
        <PageHeader>
          <Heading as="h1" variant="title">
            My Account
          </Heading>
          <PageHeaderLinks>
            <StyledLink
              as={Link}
              to="/pages/referral-dashboard"
              variant="small"
            >
              View my referrals
            </StyledLink>
            <span>|</span>
            <StyledLink onClick={onLogout} variant="small">
              {translate('account.profile.logout')}
            </StyledLink>
          </PageHeaderLinks>
        </PageHeader>
        <div
          style={{
            gridColumn: '1 / 3',
            maxWidth: 576,
            margin: '0 auto',
            width: '100%'
          }}
        >
          <AccountAddressesList user={user} />
          <Button onClick={onAdd} mt={16} mb={32}>
            {translate('account.addresses.add_button')}
          </Button>
          {isAdding && (
            <Fragment>
              <Heading as="h2">
                {translate('account.addresses.add_address_title')}
              </Heading>
              <AccountAddressesForm
                onSubmit={onAddSubmit}
                onCancel={() => setIsAdding(false)}
                sx={{ paddingBottom: '1rem', marginBottom: '1rem' }}
              />
            </Fragment>
          )}
        </div>
      </Wrapper>
    </Container>
  )
}

export default AccountAddressPage
