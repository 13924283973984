/** @jsx jsx */
import { jsx, Button, Flex, Input, Label, Link as StyledLink } from 'theme-ui'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { navigate, Link } from 'gatsby'
import { useAuth, useTranslate } from '@chordcommerce/gatsby-theme-performance'
import { useLocation } from '@reach/router'

const AuthLoginForm = ({ pathOnSubmit }) => {
  const { register, handleSubmit } = useForm()
  const translate = useTranslate()
  const { login } = useAuth()
  const [error, setError] = useState(null)

  const { search } = useLocation()

  const onLogin = async ({ email, password }) => {
    setError(null)
    try {
      await login({ email, password })
      navigate(pathOnSubmit || '/account/')
    } catch (error) {
      console.log(error)
      setError(error)
    }
  }

  return (
    <form onSubmit={handleSubmit(onLogin)}>
      <Label htmlFor="email">
        {translate('account.login.email_input_label')}
      </Label>
      <Input
        ref={register()}
        name="email"
        type="email"
        autocomplete="email"
        mb={3}
        id="email"
      />
      <Label htmlFor="password">
        {translate('account.login.password_input_label')}
      </Label>
      <Input
        ref={register()}
        name="password"
        type="password"
        autocomplete="current-password"
        mb={3}
        id="password"
      />
      {error && <p className="error">{error.message}</p>}
      <Button type="submit" sx={{ width: '100%' }}>
        {translate('account.login.button')}
      </Button>
      <Flex sx={{ flexDirection: 'column', alignItems: 'center' }}>
        {search.includes('activated') && (
          <p sx={{ textAlign: 'center' }}>
            Account successfully activated. Log in to continue
          </p>
        )}
        <StyledLink
          as={Link}
          to="/account/forgot/"
          variant="small"
          sx={{
            margin: '32px 0 16px'
          }}
        >
          {translate('account.login.forgot_link')}
        </StyledLink>
        <StyledLink as={Link} to="/account/register/" variant="small">
          {translate('account.login.register_link')}
        </StyledLink>
      </Flex>
    </form>
  )
}
export default AuthLoginForm
