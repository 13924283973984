/** @jsx jsx */
import { jsx, Label, Button, Input } from 'theme-ui'
import { useEffect, useState, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { navigate } from 'gatsby'
import { useAuth, useTranslate } from '@chordcommerce/gatsby-theme-performance'

const AuthResetPasswordForm = () => {
  const { register, errors, handleSubmit, watch } = useForm({})
  const translate = useTranslate()
  const password = useRef({})
  password.current = watch('password', '')

  const { resetPassword } = useAuth()
  const [error, setError] = useState(null)
  const [id, setId] = useState()
  const [token, setToken] = useState()

  useEffect(() => {
    const search = window.location.search
    const params = new URLSearchParams(search)
    const idParam = params.get('id')
    const tokenParam = params.get('token')
    setId(parseInt(idParam))
    setToken(tokenParam)
  }, [])

  const onResetPassword = async ({ password }) => {
    setError(null)
    try {
      await resetPassword({ id, password, token })
      navigate('/account/')
    } catch (error) {
      setError(error)
    }
  }

  return (
    <form onSubmit={handleSubmit(onResetPassword)}>
      <Label htmlFor="password">
        {translate('account.reset_password.password_input_label')}
      </Label>
      <Input
        ref={register({
          required: true,
          minLength: {
            value: 5,
            message: 'Password is too short (minimum is 5 characters)'
          }
        })}
        name="password"
        type="password"
        autocomplete="new-password"
        aria-invalid={errors.name ? 'true' : 'false'}
        mb={3}
      />
      {errors.password && <p>{errors.password.message}</p>}

      <Label htmlFor="password_repeat">
        {translate('account.reset_password.confirm_password_input_label')}
      </Label>
      <Input
        ref={register({
          validate: value =>
            value === password.current || 'The passwords do not match'
        })}
        name="password_repeat"
        type="password"
        autocomplete="new-password"
        aria-invalid={errors.name ? 'true' : 'false'}
      />
      {errors.password_repeat && <p>{errors.password_repeat.message}</p>}

      {error && <p className="error">{error.message}</p>}
      <Button type="submit" mt={3} sx={{ width: '100%' }}>
        {translate('account.reset_password.button')}
      </Button>
    </form>
  )
}
export default AuthResetPasswordForm
