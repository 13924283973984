/** @jsx jsx */
import { jsx, Label, Button, Input, Text, Link as StyledLink } from 'theme-ui'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { navigate, Link } from 'gatsby'
import { useAuth, useTranslate } from '@chordcommerce/gatsby-theme-performance'

const AuthRegisterForm = () => {
  const { register, handleSubmit } = useForm()
  const translate = useTranslate()
  const { register: registerUser } = useAuth()
  const [error, setError] = useState(null)

  const onRegister = async ({ email, password }) => {
    setError(null)
    try {
      await registerUser({ email, password })
      navigate('/account/')
    } catch (error) {
      setError(error)
    }
  }

  return (
    <form onSubmit={handleSubmit(onRegister)}>
      <Label htmlFor="email">
        {translate('account.register.email_input_label')}
      </Label>
      <Input
        ref={register()}
        name="email"
        type="email"
        autocomplete="email"
        mb={3}
      />
      <Label htmlFor="password">
        {translate('account.register.password_input_label')}
      </Label>
      <Input
        ref={register()}
        name="password"
        type="password"
        autocomplete="new-password"
        mb={3}
      />
      {error && (
        <Text className="error" variant="error" mb={3}>
          • {error.message}
        </Text>
      )}
      <Button type="submit" mb={4} sx={{ width: '100%' }}>
        {translate('account.register.button')}
      </Button>
      <StyledLink
        as={Link}
        to="/account/login"
        variant="small"
        sx={{
          display: 'block',
          margin: '0 auto',
          width: 'fit-content'
        }}
      >
        {translate('account.register.login_link')}
      </StyledLink>
    </form>
  )
}
export default AuthRegisterForm
