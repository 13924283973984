/** @jsx jsx */
import { jsx, Label, Button, Input } from 'theme-ui'
import { useEffect, useState, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { navigate } from 'gatsby'
import { useTranslate } from '@chordcommerce/gatsby-theme-performance'
import activateAccount from './script'

const AccountActivateForm = () => {
  const { register, errors, handleSubmit, watch } = useForm({})
  const translate = useTranslate()
  const password = useRef({})
  password.current = watch('password', '')

  const [error, setError] = useState(null)
  const [id, setId] = useState()
  const [token, setToken] = useState()

  useEffect(() => {
    const search = window.location.search
    const params = new URLSearchParams(search)
    const idParam = params.get('id')
    const tokenParam = params.get('token')
    setId(parseInt(idParam))
    setToken(tokenParam)
  }, [])

  useEffect(() => {
    console.log(error, errors)
  }, [error, errors])

  const onActivateAccount = async ({ password }) => {
    setError(null)
    try {
      const { response } = await activateAccount(id, token, password)
      if (response?.data?.errors?.length > 0) {
        setError(response.data.errors[0])
        console.log(response.data.errors[0].message)
      } else {
        navigate('/account/?activated=true')
      }
    } catch (error) {
      setError(error)
    }
  }

  return (
    <form onSubmit={handleSubmit(onActivateAccount)}>
      <Label htmlFor="password">
        {translate('account.activate.password_input_label')}
      </Label>
      <Input
        ref={register({
          required: true,
          minLength: {
            value: 5,
            message: 'Password is too short (minimum is 5 characters)'
          }
        })}
        name="password"
        type="password"
        autocomplete="new-password"
        aria-invalid={errors.name ? 'true' : 'false'}
        mb={3}
      />
      {errors.password && <p>{errors.password.message}</p>}

      <Label htmlFor="password_repeat">
        {translate('account.activate.confirm_password_input_label')}
      </Label>
      <Input
        ref={register({
          validate: value =>
            value === password.current || 'The passwords do not match'
        })}
        name="password_repeat"
        type="password"
        autocomplete="new-password"
        aria-invalid={errors.name ? 'true' : 'false'}
      />
      {errors.password_repeat && <p>{errors.password_repeat.message}</p>}

      {error && <p className="error">{error.message}</p>}
      <Button type="submit" mt={3} sx={{ width: '100%' }}>
        {translate('account.activate.button')}
      </Button>
    </form>
  )
}
export default AccountActivateForm
