/** @jsx jsx */
import { Fragment } from 'react'
import { jsx, Box, Heading, Grid, Text, Link } from 'theme-ui'
import { useTranslate } from '@chordcommerce/gatsby-theme-performance'

const AccountProfileOrderHistory = ({ orders, ...props }) => {
  const translate = useTranslate()

  return (
    <Box {...props}>
      <Heading
        as="h2"
        sx={{
          variant: 'responsive.desktop',
          lineHeight: '1em',
          textAlign: ['center', 'left'],
          paddingBottom: '1rem',
          marginBottom: '1.5rem'
        }}
      >
        {translate('account.profile.order_history_title')}
      </Heading>
      <Grid columns={3} gap={'1rem 2rem'} sx={{ overflowX: 'auto' }}>
        <Text variant="tableHeader">
          {translate('account.profile.column_date')}
        </Text>
        <Text variant="tableHeader">
          {translate('account.profile.column_number')}
        </Text>
        <Text variant="tableHeader">
          {translate('account.profile.column_status')}
        </Text>
        {orders &&
          orders.map(order => (
            <Fragment key={order.id}>
              <Text variant="small">
                {new Date(order.processedAt).toLocaleDateString()}
              </Text>
              <Text variant="small">{order.name}</Text>
              <Text variant="small">
                <Link href={order.statusUrl} sx={{ variant: 'text.link' }}>
                  {order.fulfillmentStatus}
                </Link>
              </Text>
            </Fragment>
          ))}
      </Grid>
    </Box>
  )
}

export default AccountProfileOrderHistory
