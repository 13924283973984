/** @jsx jsx */

import { Link as StyledLink, Container, Heading, jsx } from 'theme-ui'
import {
  useAuth,
  useTranslate,
  useUser,
  UserStatus
} from '@chordcommerce/gatsby-theme-performance'
import Link from 'gatsby-link'
import Metadata from '~/components/Metadata'
import AccountProfileOrderHistory from '~/components/Account/Profile/OrderHistory'
import AccountProfileDetails from '~/components/Account/Profile/Details'
import PageSpinner from '~/components/Generic/PageSpinner'
import { Wrapper, PageHeader, PageHeaderLinks } from './styles'

const AccountProfilePage = () => {
  const translate = useTranslate()
  const { logout } = useAuth()
  const { user, status } = useUser()

  const onLogout = async () => {
    await logout()
  }

  if (status !== UserStatus.Succeeded) {
    return <PageSpinner />
  }

  return (
    <Container variant="small">
      <Metadata title={translate('account.profile.page_title')} />
      <Wrapper>
        <PageHeader>
          <Heading as="h1" variant="title">
            My Account
          </Heading>
          <PageHeaderLinks>
            <StyledLink
              as={Link}
              to="/pages/referral-dashboard"
              variant="small"
            >
              View my referrals
            </StyledLink>
            <span>|</span>
            <StyledLink onClick={onLogout} variant="small">
              {translate('account.profile.logout')}
            </StyledLink>
          </PageHeaderLinks>
        </PageHeader>
        <AccountProfileOrderHistory orders={user.orders} />
        <AccountProfileDetails user={user} />
      </Wrapper>
    </Container>
  )
}

export default AccountProfilePage
