import axios from 'axios'

const SHOPIFY_SUBDOMAIN = process.env.GATSBY_SHOPIFY_SUBDOMAIN
const SHOPIFY_STOREFRONT_ACCESS_TOKEN =
  process.env.GATSBY_SHOPIFY_STOREFRONT_ACCESS_TOKEN
const activateAccount = async (id, token, password) => {
  const encodeShopifyCustomerID = Buffer.from(
    `gid://shopify/Customer/${id}`
  ).toString('base64')

  const query = `
  mutation customerActivate($id: ID!, $input: CustomerActivateInput!) {
    customerActivate(id: $id, input: $input) {
      customerUserErrors {
        code
        field
        message
      }
      customer {
        id
      }
    }
  }
      `

  const variables = {
    id: encodeShopifyCustomerID,
    input: {
      activationToken: token,
      password
    }
  }

  const response = await axios.post(
    `https://${SHOPIFY_SUBDOMAIN}.myshopify.com/api/2021-01/graphql.json`,
    {
      query,
      variables
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'X-Shopify-Storefront-Access-Token': SHOPIFY_STOREFRONT_ACCESS_TOKEN
      }
    }
  )

  return { response }
}

export default activateAccount
