/** @jsx jsx */
import { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Text, Spinner, jsx } from 'theme-ui'
import { logSentryError } from '@chordcommerce/gatsby-theme-performance'

const ButtonAsync = ({ children, onClick, onError, ...props }) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleClick = async () => {
    if (isLoading) return
    setIsLoading(true)
    try {
      await onClick()
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      if (onError) {
        onError(error)
      } else {
        logSentryError(error)
      }
    }
  }

  return (
    <Button type="button" onClick={handleClick} {...props}>
      {isLoading && (
        <Spinner
          aria-label="Loading..."
          data-testid="spinner"
          size="15"
          color="inherit"
        />
      )}
      {!isLoading && <Text>{children}</Text>}
    </Button>
  )
}

ButtonAsync.propTypes = {
  onClick: PropTypes.func.isRequired,
  onError: PropTypes.func
}

export default ButtonAsync
