/** @jsx jsx */
import { jsx, Label, Button, Input } from 'theme-ui'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useAuth, useTranslate } from '@chordcommerce/gatsby-theme-performance'

const AuthForgotPasswordForm = () => {
  const { register, handleSubmit } = useForm()
  const translate = useTranslate()
  const { recover } = useAuth()
  const [error, setError] = useState(null)
  const [submitted, setSubmitted] = useState(false)

  const onForgotPassword = async ({ email }) => {
    setError(null)
    try {
      await recover(email)
      setSubmitted(true)
    } catch (error) {
      setError(error)
    }
  }

  return (
    <form onSubmit={handleSubmit(onForgotPassword)}>
      <Label htmlFor="email">
        {translate('account.forgot_password.email_input_label')}
      </Label>
      <Input ref={register()} name="email" type="email" autocomplete="email" />
      {error && <p className="error">{error.message}</p>}
      {submitted && <p>{translate('account.forgot_password.confirmation')}</p>}
      <Button type="submit" mt={3} sx={{ width: '100%' }}>
        {translate('account.forgot_password.button')}
      </Button>
    </form>
  )
}
export default AuthForgotPasswordForm
