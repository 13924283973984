import styled from '@emotion/styled'

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 64px 40px;
  padding-bottom: 64px;

  @media screen and (min-width: 768px) {
    grid-template-columns: 2fr 1fr;
    grid-gap: 100px 40px;
  }
`

export const PageHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: 768px) {
    grid-column-start: 1;
    grid-column-end: 3;
  }
`

export const PageHeaderLinks = styled.div`
  display: flex;
  align-items: center;
  margin-top: -16px;

  span {
    color: gray;
    margin: 0 15px;
    font-size: 14px;
  }

  @media screen and (min-width: 576px) {
    margin-top: -32px;
  }
`
