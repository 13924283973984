/** @jsx jsx */
import { useState } from 'react'
import PropTypes from 'prop-types'
import { jsx, Flex, Button, Heading, Text } from 'theme-ui'
import { useTranslate, useUser } from '@chordcommerce/gatsby-theme-performance'
import AccountAddressesForm from '~/components/Account/Addresses/Form'
import AccountAddressesAddressText from '~/components/Account/Addresses/AddressText'
import ButtonAsync from '~/components/Generic/ButtonAsync'

const AccountAddressesAddress = ({
  address,
  showControls = false,
  isDefaultAddress = false
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const [error, setError] = useState()
  const { modifyUserAddress, removeUserAddress } = useUser()
  const translate = useTranslate()

  if (!address) return null

  const onDelete = async () => {
    await removeUserAddress(address.id)
  }

  const onEdit = () => {
    setIsEditing(!isEditing)
  }

  const onError = error => {
    setError(error)
  }

  const onEditSubmit = async formData => {
    await modifyUserAddress(
      address.id,
      formData.address,
      formData.isDefaultAddress
    )
    setIsEditing(false)
  }

  return (
    <div
      style={{
        marginBottom: '16px',
        paddingBottom: '16px',
        borderBottom: '1px solid #ddd'
      }}
    >
      {isDefaultAddress && (
        <Heading as="h4" mt={3}>
          {translate('account.addresses.default_address_label')}
        </Heading>
      )}

      {!isEditing && (
        <div>
          <AccountAddressesAddressText address={address} />

          {showControls && (
            <Flex sx={{ alignItems: 'center', marginTop: '8px' }}>
              <Button
                type="button"
                onClick={onEdit}
                variant="clear"
                sx={{ fontSize: '1.5rem' }}
              >
                {translate('account.addresses.edit_button')}
              </Button>
              <div
                style={{
                  width: '1px',
                  height: 15,
                  margin: '-3px 8px 0',
                  background: 'black'
                }}
              />
              <ButtonAsync
                onClick={onDelete}
                onError={onError}
                variant="clear"
                sx={{ fontSize: '1.5rem' }}
              >
                {translate('account.addresses.delete_button')}
              </ButtonAsync>
            </Flex>
          )}
        </div>
      )}

      {error && <Text variant="formError">{error.message}</Text>}

      {isEditing && (
        <AccountAddressesForm
          address={address}
          isDefaultAddress={isDefaultAddress}
          onSubmit={onEditSubmit}
          onCancel={() => setIsEditing(false)}
        />
      )}
    </div>
  )
}

AccountAddressesAddress.propTypes = {
  address: PropTypes.shape({
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    company: PropTypes.string,
    country: PropTypes.string,
    countryCodeV2: PropTypes.string,
    firstName: PropTypes.string,
    id: PropTypes.string.isRequired,
    lastName: PropTypes.string,
    phone: PropTypes.string,
    province: PropTypes.string,
    provinceCode: PropTypes.string,
    zip: PropTypes.string
  }),
  isDefaultAddress: PropTypes.bool
}

export default AccountAddressesAddress
